import Image from "next/image";

import logo from "../assets/beder-logo.png";
import MenuburgerButton from "./menuburger-icon";
import Nav from "./nav";
import Link from "next/link";
import { useTranslation } from "@hooks/useTranslation";

export default function Header() {
  const { t } = useTranslation();
  const navItems = [
    {
      text: t("navigation.website"),
      link: `/home`,
    },
   /* {
      text:t("navigation.bstudio"),
      link: `/bstudio`,
    },*/
    {
      text: t("navigation.app"),
      link: `/theapp`,
    },
    {
      text: t("navigation.about"),
      link: `/aboutus`,
    },
    {
      text: t("navigation.part"),
      link: `/bepartof`,
    },
  ];
  return (
    <header className="fixed top-0 flex items-center px-2 md:px-[10%] md:py-2 w-full shadow backdrop-blur-3xl z-50">
      <div className="flex items-center justify-between px-2 gap-2 md:flex-col w-full">
        <MenuburgerButton items={navItems} />
        <Link href="/home" className="md:self-start">
          <Image src={logo} alt="Beder's logo" width={110} />
        </Link>
        <div className="md:hidden w-7" />
      </div>
      <Nav items={navItems} />
    </header>
  );
}
