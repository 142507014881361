import Link from "next/link";
import LocaleSwitcher from "./locale-switcher";
import { dangerouslySetInnerHTML } from "@lib/utils";
import CurrencySwitcher from "./currency-switcher";

export default function Nav({
  items,
}: {
  items: { text: string; link: string }[];
}) {
  return (
    <nav>
      <ul className="hidden md:flex items-center gap-x-8">
        {items.map(({ text, link }, idx) => (
          <li key={idx} className="uppercase whitespace-nowrap">
            <Link href={link}>
              <p dangerouslySetInnerHTML={dangerouslySetInnerHTML(text)} />
            </Link>
          </li>
        ))}
        <li>
          <LocaleSwitcher />
        </li>
        <li>
          <CurrencySwitcher />
        </li>
      </ul>
    </nav>
  );
}
