import { useState, useEffect } from "react";
import Link from "next/link";
import Cookies from "js-cookie";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";
import { Modal } from "./shared";
import { MdChevronRight } from "react-icons/md";
import { useTranslation } from "next-i18next";

const USER_CONSENT_COOKIE_KEY = "enabled_cookies";
const USER_CONSENT_COOKIE_EXPIRE_DATE = 365;

export const CookieConsent = () => {
  const { t } = useTranslation();

  const [showCookieConsent, setShowCookieConsent] = useState(true);
  const [showCookiesSettings, setShowCookiesSettings] = useState(false);

  useEffect(() => {
    const consentIsTrue = !!Cookies.get(USER_CONSENT_COOKIE_KEY);
    setShowCookieConsent(consentIsTrue);
  }, []);

  const onAccept = () => {
    if (!showCookieConsent) {
      Cookies.set(USER_CONSENT_COOKIE_KEY, "all", {
        expires: USER_CONSENT_COOKIE_EXPIRE_DATE,
      });
      setShowCookieConsent(true);
    }
  };

  if (showCookieConsent) {
    return null;
  }

  return (
    <>
      <div className="fixed z-[9999] bottom-0 flex flex-col items-start w-full px-4 py-3 space-y-2 bg-neutral-700 md:flex-row md:space-y-0 md:items-stretch md:space-x-2">
        <div className="flex items-center flex-grow text-white">
          <p className="text-xs font-medium md:text-sm">{t("COOKIES.TEXT")}</p>
        </div>
        <div className="flex justify-between md:justify-end w-full gap-4">
          <button
            className="p-3 text-xs font-bold uppercase bg-gray-200 md:text-sm w-1/2 md:w-auto"
            onClick={() => setShowCookiesSettings(true)}
          >
            {t("COOKIES.SETTINGS")}
          </button>
          <button
            className="p-3 text-xs font-bold text-white uppercase md:text-sm bg-primary-color w-1/2 md:w-auto"
            onClick={onAccept}
          >
            {t("COOKIES.ACCEPT_ALL")}
          </button>
        </div>
      </div>
      <CookieSettings
        show={showCookiesSettings}
        onClose={() => setShowCookieConsent(true)}
      />
    </>
  );
};

const CookieSettings = ({
  show,
  onClose,
}: {
  show: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation();

  const items = [
    {
      id: 0,
      heading: t("COOKIES.NECESSARY"),
      content: t("COOKIES.NECESSARY_CONTENT"),
    },
    {
      id: 1,
      heading: t("COOKIES.FUNCTIONAL"),
      content: t("COOKIES.FUNCTIONAL_CONTENT"),
    },
    {
      id: 2,
      heading: t("COOKIES.PERFORMANCE"),
      content: t("COOKIES.PERFORMANCE_CONTENT"),
    },
    {
      id: 3,
      heading: t("COOKIES.ANALYTICS"),
      content: t("COOKIES.ANALYTICS_CONTENT"),
    },
    {
      id: 4,
      heading: t("COOKIES.ADVERTISEMENT"),
      content: t("COOKIES.ADVERTISEMENT_CONTENT"),
    },
    {
      id: 5,
      heading: t("COOKIES.OTHERS"),
      content: t("COOKIES.OTHERS_CONTENT"),
    },
  ];

  const [selectedIDs, setSelectedIDs] = useState([0]);

  const save = () => {
    onClose();

    if (items.length === selectedIDs.length) {
      Cookies.set(USER_CONSENT_COOKIE_KEY, "all", {
        expires: USER_CONSENT_COOKIE_EXPIRE_DATE,
      });

      return;
    }

    const enabledCookies = selectedIDs
      .map((id) => items[id].heading.toLowerCase())
      .join("_");

    Cookies.set(USER_CONSENT_COOKIE_KEY, enabledCookies, {
      expires: USER_CONSENT_COOKIE_EXPIRE_DATE,
    });
  };

  return (
    <Modal isOpen={show}>
      <div className="z-50 flex flex-col p-8 overflow-y-auto bg-gray-200 md:mx-48">
        <h3 className="text-lg font-semibold capitalize">
          {t("COOKIES.PRIVACY_OVERVIEW")}
        </h3>
        <p className="text-sm">{t("COOKIES.TEXT")}</p>
        <button
          onClick={save}
          className="p-4 mt-2 font-semibold uppercase bg-primary-color md:self-end"
        >
          {t("COOKIES.ACCEPT_AND_SAVE")}
        </button>
        <Accordion allowMultipleExpanded allowZeroExpanded>
          {items.map((item) => (
            <AccordionItem key={item.id} className="my-4">
              <AccordionItemHeading className="p-4 bg-gray-300 rounded-lg">
                <AccordionItemButton className="flex items-center justify-between w-full">
                  <div className="flex items-center justify-center gap-x-1">
                    <AccordionItemState>
                      {({ expanded }) =>
                        expanded ? (
                          <MdChevronRight className="text-2xl -rotate-90" />
                        ) : (
                          <MdChevronRight className="text-2xl rotate-90" />
                        )
                      }
                    </AccordionItemState>
                    <p className="first-letter:uppercase">{item.heading}</p>
                  </div>
                  {item.id === 0 ? (
                    <p className="capitalize">{t("COOKIES.ACCEPT_AND_SAVE")}</p>
                  ) : (
                    <Switch
                      id={item.id}
                      isOn={selectedIDs.includes(item.id)}
                      handleToggle={() => {
                        setSelectedIDs(
                          selectedIDs.includes(item.id)
                            ? selectedIDs.filter((id) => id !== item.id)
                            : [...selectedIDs, item.id]
                        );
                      }}
                    />
                  )}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel className="p-4">
                {item.content}
              </AccordionItemPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </Modal>
  );
};

const Switch = ({
  id,
  isOn,
  handleToggle,
}: {
  id: number;
  isOn: boolean;
  handleToggle: () => void;
}) => {
  return (
    <>
      <input
        checked={isOn}
        onChange={handleToggle}
        className="hidden w-0 h-0 peer"
        id={`switch-${id}`}
        type="checkbox"
        role="switch"
      />
      <label
        className={`flex items-center justify-between cursor-pointer w-12 h-6 bg-gray-400 rounded-full relative transition-all duration-200 group peer-checked:[&>*]:translate-x-full peer-checked:bg-primary-color`}
        htmlFor={`switch-${id}`}
      >
        <span className="absolute w-5 h-5 duration-200 bg-gray-200 rounded-full shadow left-1" />
      </label>
    </>
  );
};
