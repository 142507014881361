import {
  CampaignId,
  InfluencerId,
  SessionId,
  getABTestingFlag,
  getUserId,
} from "@redux/sessionStorage/sessionItem";
import { getMobileDetect } from "./functions";
import axios from "axios";
import languageDetector from "@lib/languageDetector";

export const track = async (
  event: keyof typeof events,
  data: Record<string, string | number> = {}
) => {
  try {
    const user_id = getUserId();
    const session_id = SessionId();
    const campaign_id = CampaignId();
    const AB_testing_flag = getABTestingFlag();
    const inf_id = InfluencerId();

    const body = {
      event_name: event,
      user_id,
      session_id,
      ...(campaign_id ? { campaign_id } : {}),
      device_type: getMobileDetect(window.navigator.userAgent).isMobile()
        ? "mobile"
        : "desktop",
      lang: languageDetector.detect(),
      OS: window.navigator.platform,
      page: `${window.location.pathname}${window.location.search}`,
      AB_testing_flag,
      inf_id,
      ...data,
    };
    
    await axios("/api/track", {
      method: "POST",
      data: body,
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (error) {
    console.log(error);
  }
};

export const events = {
  CHECK_ACTIVITY: "CHECK_ACTIVITY",
  PLAY_VIDEO_EVENT: "PLAY_VIDEO_EVENT",
  CLICK_SEE_MORE: "CLICK_SEE_MORE",
  CLICK_NEXT_EXP_CATEGORY: "CLICK_NEXT_EXP_CATEGORY",
  CLICK_USER_TOP_EXP: "CLICK_USER_TOP_EXP",
  CLICK_MAP_TOP_EXP: "CLICK_MAP_TOP_EXP",
  SEARCH_CITY: "SEARCH_CITY",
  TOP_PLACE_CLICKED: "TOP_PLACE_CLICKED",
  CLICK_NEXT_VIDEO: "CLICK_NEXT_VIDEO",
  CHECK_HOTEL: "CHECK_HOTEL",
  MOBILE_CLICK_EXPERIENCES_TAB: "MOBILE_CLICK_EXPERIENCES_TAB",
  MOBILE_CLICK_MUSTSEE_TAB: "MOBILE_CLICK_MUSTSEE_TAB",
  MOBILE_CLICK_ITINERARY_TAB: "MOBILE_CLICK_ITINERARY_TAB",
  MOBILE_CLICK_HOTELS_TAB: "MOBILE_CLICK_HOTELS_TAB",
  MOBILE_CLICK_ACTIVITY_DETAILS: "MOBILE_CLICK_ACTIVITY_DETAILS",
  MOBILE_FULLSCREEN_CLICK_ACTIVITY_DETAILS:
    "MOBILE_FULLSCREEN_CLICK_ACTIVITY_DETAILS",
  MOBILE_GALLERY_VIEW_2X2: "MOBILE_GALLERY_VIEW_2X2",
  MOBILE_GALLERY_VIEW_1X1: "MOBILE_GALLERY_VIEW_1X1",
  MOBILE_CLICK_MAP: "MOBILE_CLICK_MAP",
  CLICK_FILTER_HOTEL: "CLICK_FILTER_HOTEL",
  CLICK_FILTER_ACTIVITIES: "CLICK_FILTER_ACTIVITIES",
  MOBILE_CLICK_CARD_VIDEO_CLASS: "MOBILE_CLICK_CARD_VIDEO_CLASS",
  CLICK_CLOSE_ACTIVITY_BUTTON: "CLICK_CLOSE_ACTIVITY_BUTTON",
  CLICK_HOTELS_CHECK_AVAILABILITY: "CLICK_HOTELS_CHECK_AVAILABILITY",
  FULLSCREEN_SWIPE_NEXT_VIDEO: "FULLSCREEN_SWIPE_NEXT_VIDEO",
  FULLSCREEN_SWIPE_NEXT_EXP: "FULLSCREEN_SWIPE_NEXT_EXP",
  PAGE_VIEW: "PAGE_VIEW",
  BOOK_ACTIVITY: "BOOK_ACTIVITY",
  CHECK_ACTIVITY_DETAILS: "CHECK_ACTIVITY_DETAILS",
  CLOSE_ACTIVITY_DETAILS: "CLOSE_ACTIVITY_DETAILS",
  CHECK_AVAILABILITY: "CHECK_AVAILABILITY",
  CHECKOUT: "CHECKOUT",
  BOOK_NOW: "BOOK_NOW",
  PAY: "PAY",
  KEEP_DISCOVERING: "KEEP_DISCOVERING",
  CLICK_RECOMMENDED_EXPERIENCE: "CLICK_RECOMMENDED_EXPERIENCE",
  CLICK_EXPERIENCE_CARD: "CLICK_EXPERIENCE_CARD",
  CLICK_PLACE_TO_VISIT_CARD: "CLICK_PLACE_TO_VISIT_CARD",
  CLICK_HOTEL_CARD: "CLICK_HOTEL_CARD",
  CLOSE_EXPERIENCE_VIDEO: "CLOSE_EXPERIENCE_VIDEO",
  CLOSE_PLACE_TO_VISIT_VIDEO: "CLOSE_PLACE_TO_VISIT_VIDEO",
  CLOSE_HOTEL_VIDEO: "CLOSE_HOTEL_VIDEO",
  PLAY_EXPERIENCE_VIDEO: "PLAY_EXPERIENCE_VIDEO",
  PLAY_HOTEL_VIDEO: "PLAY_HOTEL_VIDEO",
  EXPAND_ACTIVITIES: "EXPAND_ACTIVITIES",
  SHRINK_ACTIVITIES: "SHRINK_ACTIVITIES",
} as const;
