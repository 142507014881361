import { useState } from "react";
import Image from "next/image";

import QR from "@assets/qr.jpg";

import { useTranslation } from "@hooks/useTranslation";
import { useMobileDetect } from "@hooks/useMobileDetect";

export const UpperPartofFooter = () => {
  const { t } = useTranslation();
  const [qrvisible, setQRvisible] = useState(false);
  const currentDevice = useMobileDetect();

  const { isAndroid } = useMobileDetect();

  const handleGetTheApp = () => {
    const playStoreUrl =
      "https://play.google.com/store/apps/details?id=com.bederapp";
    const appStoreUrl =
      "https://apps.apple.com/app/beder-l-explore-travel/id1609301338";

    const appUrl = isAndroid() ? playStoreUrl : appStoreUrl;

    window.open(appUrl, "_blank", "noreferrer");
  };

  function openQR() {
    if (currentDevice.isMobile()) {
     handleGetTheApp();

    } else {
      setQRvisible(!qrvisible);
    }
  }

  return (
    <>
      <div className="relative flex items-center justify-center w-full mb-0 ">
        <div
          className={
            !qrvisible
              ? "hidden"
              : " absolute text-center align-middle z-10 p-10 bg-white top-[100px] shadow-2xl rounded-3xl flex items-center justify-center"
          }
        >
          <Image
            src={QR}
            width={250}
            height={250}
            alt={"logo"}
            className="m-auto"
          />
        </div>
      </div>
      <div className="relative flex items-center w-full mb-10 md:justify-between ">
        <hr className="absolute z-0 visible w-full h-5 bg-black border-0 md:invisible"></hr>
        <hr className="flex-auto invisible h-2 bg-black border md:w-full md:visible border-slate-700" />
        <h3 className=" z-0   mr-0 w-1/3 md:w-[800px] text-[11px] md:text-[14px] text-center md:text-right text-white  md:ml-8  md:text-black">
          {t("footer.join")}
        </h3>

        <button
          onClick={() => openQR()}
          className="z-0 px-4 md:px-10 py-3 w-1/3 md:w-[600px] text-[12px] font-bold tracking-wider text-black rounded-full outline-none md:mx-6 bg-primary-color "
        >
          {t("footer.get_app")}
        </button>
        <h3 className=" z-0 mr-0 w-1/3 md:w-[800px] text-[11px] md:text-[14px] text-center md:text-left text-white  md:text-black  md:mr-8">
          {t("footer.discover")}
        </h3>
        <hr className="invisible md:w-full md:visible flex-auto h-2 bg-black border-[1px] border-slate-700" />
      </div>
    </>
  );
};
