import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

import type { Locale } from "../../i18n-config";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const dangerouslySetInnerHTML = (text: string) => {
  return { __html: text };
};

export const pickByLocale = <T>(item: Record<Locale, T>, locale: Locale) => {
  return item[locale] || item.en;
};

export const capitalize = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};
