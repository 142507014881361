import Flags from "country-flag-icons/react/1x1";
import { LANGUAGE_COUNTRY_CODE } from "@shared/constants";

export const DropDownListItem = ({
  isSelected,
  stateTitle,
  onClick,
  langs,
  additionalStyles,
}: {
  isSelected?: boolean;
  stateTitle?: string;
  onClick?: Function;
  langs?: string[];
  additionalStyles?: string;
}) => {
  return (
    <div
      onClick={() => onClick && onClick()}
      className={`w-full px-4 py-2  text-sm font-semibold cursor-pointer flex items-center ${
        isSelected && "bg-primary-color/30"
      } ${additionalStyles} `}
    >
      <div className="mr-2">{stateTitle}</div>
      {langs && <LanguagesFlags langs={langs} />}
    </div>
  );
};

const LanguagesFlags = ({ langs }: { langs: string[] }) => {
  return (
    <div className="text-[12px] font-normal flex space-x-2 ">
      {langs?.map((lang) => {
        const flagLang = LANGUAGE_COUNTRY_CODE.find((item) =>
          item.alias.includes(lang)
        );
        const Flag = Flags[flagLang?.code as keyof typeof Flags];

        return Flag ? (
          <div
            key={lang}
            className="flex items-center justify-center w-5 h-5 overflow-hidden border-2 border-gray-400 rounded-md"
          >
            <Flag className="w-full h-full" />
          </div>
        ) : null;
      })}
    </div>
  );
};
