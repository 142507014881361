import Cookies from "js-cookie";
import uuid from "react-uuid";

export const SessionId = () => {
  if (typeof window !== "undefined") {
    let sessionId = window.sessionStorage.getItem("SessionId")?.toString();

    if (sessionId == undefined) {
      sessionId = uuid();
      window.sessionStorage.setItem("SessionId", sessionId);
    }
    return sessionId;
  }
  return null;
};

export const CampaignId = () => {
  if (typeof window !== "undefined") {
    let campaignId = window.sessionStorage.getItem("CampaignId")?.toString();

    const search = new URLSearchParams(window.location.search);

    if (search.has("campaign_id")) {
      campaignId = search.get("campaign_id")?.toString();

      campaignId && window.sessionStorage.setItem("CampaignId", campaignId);
      return campaignId;
    }
    return campaignId;
  }
  return null;
};

export const getABTestingFlag = () => {
  const itemName = "AB_testing_flag";
  let testingId = sessionStorage.getItem(itemName);

  if (!testingId) {
    testingId = Math.round(Math.random()).toString();
    sessionStorage.setItem(itemName, testingId);
  }

  return Number(testingId);
};

export const getUserId = () => {
  let userId = Cookies.get("user_id");
  if (!!userId) {
    return userId;
  }

  userId = uuid();

  Cookies.set("user_id", userId, {
    expires: new Date("9999-12-31"),
    path: "/",
  });

  return userId;
};

export const InfluencerId = () => {
  if (typeof window !== "undefined") {
    let infId = window.sessionStorage.getItem("InfId")?.toString();

    const search = new URLSearchParams(window.location.search);

    if (search.has("utm_campaign")) {
      infId = search.get("utm_campaign")?.toString();

      infId && window.sessionStorage.setItem("InfId", infId);
      return infId;
    }
    return infId;
  }
  return null;
};

export const getCampaignIdAndUserId = () => {
  return `${CampaignId() || ""}_${getUserId()}`;
};
