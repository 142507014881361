import Image from "next/image";
import React, { useState } from "react";

import LogoImg from "../../public/images/logo-beder-opt-1024x422.png";
import AppStore from "../../public/images/app-store.png";
import PlayStore from "../../public/images/playstore.png";
import Vector from "../../public/images/Vector.png";
import Aff3 from "../../public/images/affiliate-companies/musement_logo_long.png";
import Aff4 from "../../public/images/affiliate-companies/aff-4.svg";
import curve from "../../public/images/footer-curve.svg";
import { MdKeyboardArrowDown } from "react-icons/md";

import { UpperPartofFooter } from "./UpperPartofFooter";
import { openInNewTab } from "@shared/functions";
import { useTranslation } from "@hooks/useTranslation";
import { useRouter } from "next/router";
import languageDetector from "@lib/languageDetector";
import { cn } from "@lib/utils";
import { useMobileDetect } from "@hooks/useMobileDetect";
import Link from "next/link";

interface IFooterLayout {

  hideGet? : boolean;
}

export const Footer = ({hideGet = false }: IFooterLayout) => {

  const { t } = useTranslation();
  const router = useRouter();
  const { isMobile } = useMobileDetect();

  const baseUrlBederApp = "https://theapp.beder.app";

  const [isOpenFollowUs, setOpenFollowUs] = useState(false);
  const toggleMenuFollowUs = () => setOpenFollowUs(!isOpenFollowUs);

  const [isOpenInfo, setOpenInfo] = useState(false);
  const toggleMenuInfo = () => setOpenInfo(!isOpenInfo);

  const [isOpenBeder, setOpenBeder] = useState(false);
  const toggleMenuBeder = () => setOpenBeder(!isOpenBeder);

  const collapseFollowUs = () => {
    toggleMenuFollowUs();
  };

  const collapseInfo = () => {
    toggleMenuInfo();
  };

  const collapseBeder = () => {
    toggleMenuBeder();
  };

  const changeLocale = (language: string) => {
    languageDetector.cache?.(language);
    const { pathname, asPath, query } = router;
    router.replace({ pathname, query }, asPath, {
      locale: language,
      shallow: true,
    });
  };

  return (
    <div
      className={cn(
        "flex flex-col items-center justify-center mb-20 bg-white",
        isMobile() ? "-mt-[60px]" : "mt-20"
      )}
    >


      {/* UPPER PART */}
      {!hideGet && <UpperPartofFooter />}
    

      {/* MIDDLE PART */}
      <div className="mt-20 md:mt-0 md:mx-10 md:justify-between md:flex md:space-x-5 md:w-4/5">
        {/* 1st section */}

        <div className="relative flex-col hidden md:flex md:visible ">
          <div className="absolute invisible md:visible -z-50">
            <Image
              src={Vector}
              width={600}
              height={600}
              alt={"logo"}
              className="object-cover aspect-auto"
            />
          </div>
          <div className="invisible mb-2 md:visible">
            <Image
              src={LogoImg}
              width={250}
              height={250}
              alt={"logo"}
              className="object-cover aspect-auto"
            />
          </div>
          <div className="flex flex-col justify-around invisible md:visible">
            <h3 className="h-10 font-semibold">{t("footer.available_on")}</h3>
            <div className="w-32 h-10 mt-1">
              <button
                onClick={() =>
                  openInNewTab(
                    "https://apps.apple.com/app/beder-l-explore-travel/id1609301338"
                  )
                }
              >
                <Image
                  src={AppStore}
                  width={300}
                  height={200}
                  alt="App Store logo"
                  className="object-cover aspect-auto"
                />
              </button>
            </div>
            <div className="w-32 h-10 mt-3">
              <button
                onClick={() =>
                  openInNewTab(
                    "https://play.google.com/store/apps/details?id=com.bederapp"
                  )
                }
              >
                <Image
                  src={PlayStore}
                  width={300}
                  height={200}
                  alt="Play Store logo"
                  className="object-cover aspect-auto"
                />
              </button>
            </div>
          </div>
        </div>
        {/* 2nd section */}
        <div
          className="w-full md:w-[17rem] mb-5 mr-0 text-sm md:mb-0 md: h-30 text-slate-600 md:!ml-[7%]"
          onClick={collapseBeder}
        >
          <div className="flex items-center justify-center visible h-5 mb-5 md:mb-0 md:ml-0 md:invisible md:h-0">
            <Image src={LogoImg} width={80} height={250} alt={"logo"} />
          </div>
          <h3 className="flex w-full mb-1 font-semibold">
            <div className="w-1/2 ml-10 md:w-3/4 md:ml-0 ">BEDER</div>
            <div className="right-0 object-right w-1/2 md:w-[50px] md:relative mr-[-20px] md:mr-[1px] text-right items-right">
              <MdKeyboardArrowDown
                size={32}
                className="relative mt-[-5px]  text-primary-color m-auto right-0 text-right"
              ></MdKeyboardArrowDown>
            </div>
          </h3>
          <hr className="h-0.5 border-[0px] bg-primary-color mr-10 md:mr-0 md:ml-0 ml-10 mb-5"></hr>
          {isOpenBeder && (
            <div className="flex-col w-full md:w-full md:flex">
              <ul className="flex flex-col flex-1 ml-10 capitalize cursor-pointer md:ml-2 justify-evenly">
                <li>
                <Link href={'/home'}>
                <button onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                     <p className="text-left">  {t("footer.main_website")}</p>
                  </button>
                        
                      </Link>
                </li>
                <li>
                <Link href={'/bstudio'}>
                <button onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                     <p className="text-left">  bstudio</p>
                  </button>
                       
                      </Link>
       
                </li>
                <li>
                <Link href={'/theapp'}>
                <button onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                     <p className="text-left">   {t("footer.theapp")}</p>
                  </button>
                       
                      </Link>
       
                </li>
                <li>
                <Link href={'/aboutus'}>
                <button onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                     <p className="text-left">   {t("footer.about_us")}</p>
                  </button>
                        
                      </Link>
       
                </li>
                <li >
                <Link href={'/bepartof'}>
                <button onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                     <p className="text-left">  {t("footer.be_part_of")}</p>
                  </button>
                       
                      </Link>
          
                </li>
                <li>
                <Link href={'/blog'}>
                  <button onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                     <p className="text-left">  blog</p>
                  </button>
                </Link>
          
                </li>
              </ul>
            </div>
          )}
        </div>
        {/* 3rd section */}
        <div
          className="w-full md:w-[17rem] mb-0 mr-0 text-sm md:mb-0 md: h-30 text-slate-600 "
          onClick={collapseInfo}
        >
          <h3 className="flex w-full mb-1 font-semibold">
            <div className="w-1/2 ml-10 md:ml-0 md:w-3/4">INFO</div>
            <div className="right-0 object-right w-1/2 md:w-[50px] md:mr-[0px] mr-[-20px] text-right items-right">
              <MdKeyboardArrowDown
                size={32}
                className="relative mt-[-5px] text-primary-color m-auto right-0 text-right"
              ></MdKeyboardArrowDown>
            </div>
          </h3>
          <hr className="h-0.5 border-[0px] bg-primary-color mr-10 ml-10 mb-5 md:mr-0 md:ml-0"></hr>
          {isOpenInfo && (
            <div className="flex-col w-full mb-5 md:mb-0 md:w-full md:flex md:h-36">
              <div>
                <ul className="flex flex-col flex-1 ml-10 capitalize cursor-pointer md:ml-2 justify-evenly">
                  <li>
                  <Link href={'https://beder-app.s3.eu-west-3.amazonaws.com/PDFS/privacypolicy.pdf'}>
                  <button onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                     <p className="text-left"> 
                          {t("footer.privacy_policy")}</p>
                          </button>
                      </Link>
                    
                  
                  </li>
                  <li>
                        <Link href={'/cookies-policy'}>
                        <button onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                     <p className="text-left"> 
                          {t("footer.cookies_policy")}</p>
                          </button>
                      </Link>
                  </li>
                  <li>
                  <Link href={'https://beder-app.s3.eu-west-3.amazonaws.com/PDFS/termsservices.pdf'}>
                  <button onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                     <p className="text-left"> 
                          {t("footer.terms_and_conditions")}</p>
                          </button>
                       
                      </Link>
                  </li>
                </ul>
              </div>
            </div>
          )}
          {!isOpenInfo && (
            <div className="flex-col w-full md:w-full md:flex md:h-36"></div>
          )}
          {/* <div className="invisible h-0 font-bold md:h-4 md:visible ">
            <h3 className="font-semibold uppercase">{t("footer.languages")}</h3>
            <div>
              <button
                className={router.locale === "en" ? "text-primary-color" : ""}
                onClick={() => {
                  changeLocale("en");
                }}
              >
                EN
              </button>
              <span> | </span>
              <button
                className={router.locale === "es" ? "text-primary-color" : ""}
                onClick={() => {
                  changeLocale("es");
                }}
              >
                ES
              </button>
              <span> | </span>
              <button
                className={router.locale === "fr" ? "text-primary-color" : ""}
                onClick={() => {
                  changeLocale("fr");
                }}
              >
                FR
              </button>
            </div>
          </div> */}
        </div>

        {/* 4th section */}
        <div
          className="w-full md:w-[24rem] h-20 mb-1 mr-0 text-sm md:mb-0 md: text-slate-600 md:!mr-[7%]"
          onClick={() => collapseFollowUs()}
        >
          <h3 className="flex w-full mb-1 font-semibold">
            <div className="w-1/2 ml-10 uppercase md:ml-0 md:w-3/4">
              {t("footer.follow_us")}
            </div>
            <div className="right-0 object-right md:mr-[0px]  w-1/2 md:w-[50px] mr-[-20px] text-right items-right">
              <MdKeyboardArrowDown
                size={32}
                className="relative mt-[-5px]  text-primary-color m-auto right-0 text-right"
              ></MdKeyboardArrowDown>
            </div>
          </h3>
          <hr className="h-0.5 border-[0px] bg-primary-color mr-10 ml-10 mb-5 md:mr-0 md:ml-0"></hr>
          {isOpenFollowUs && (
            <div className="flex-col w-full md:w-full md:flex">
              <ul className="flex flex-col flex-1 ml-10 md:ml-2 justify-evenly">
                <li>
                  <button
                    onClick={() =>
                      openInNewTab("https://www.instagram.com/beder.app/")
                    }
                  >
                    Instagram
                  </button>
                </li>
                <li>
                  <button
                    onClick={() =>
                      openInNewTab(
                        "https://www.facebook.com/Bederapp-102886968820734"
                      )
                    }
                  >
                    Facebook
                  </button>
                </li>
                <li>
                  <button
                    onClick={() =>
                      openInNewTab("https://www.tiktok.com/@bederapp")
                    }
                  >
                    TikTok
                  </button>
                </li>
                <li>
                  <button
                    onClick={() =>
                      openInNewTab("https://www.linkedin.com/company/bederapp")
                    }
                  >
                    LinkedIn
                  </button>
                </li>
              </ul>
            </div>
          )}
        </div>
        <div className="w-full md:w-[20rem] !m-0">
          <div className="invisible h-0 font-bold md:h-4 md:visible ">
            <h3 className="font-semibold uppercase">{t("footer.languages")}</h3>
            <div>
              <button
                className={router.locale === "en" ? "text-primary-color" : ""}
                onClick={() => {
                  changeLocale("en");
                }}
              >
                EN
              </button>
              <span> | </span>
              <button
                className={router.locale === "es" ? "text-primary-color" : ""}
                onClick={() => {
                  changeLocale("es");
                }}
              >
                ES
              </button>
              <span> | </span>
              <button
                className={router.locale === "fr" ? "text-primary-color" : ""}
                onClick={() => {
                  changeLocale("fr");
                }}
              >
                FR
              </button>
            </div>
          </div>
        </div>

        {/* 5th section */}
        <div className={isOpenFollowUs ? "mt-20 md:mt-0" : "" + " md:mt-0"}>
          <h3 className="pb-5 font-semibold text-center uppercase md:pb-2 md:mb-5">
            {t("footer.work_partners")}
          </h3>
          <div className="grid items-center w-full grid-cols-2 gap-2">
            <div className="flex items-center justify-center">
              <div className="items-center h-6 px-6 md:w-4/6 justify-self-center">
                <Image className="object-cover" src={Aff3} alt={"Aff3"} />
              </div>
            </div>
            <div className="items-center justify-self-center">
              <Image className="object-cover" src={Aff4} alt={"Aff4"} />
            </div>
          </div>
        </div>
      </div>

      <div className="visible w-full mt-10 text-center md:hidden">
        <div className="grid content-center w-full text-center place-items-center">
          <div className="w-0 h-16 mt-5 mb-5 border-[1px] border-primary-color min-h-16">
            {" "}
          </div>
        </div>
        <span className=" font-semibold text-center text-[40px] pt-10 croogla">
          {t("footer.available_on")}
        </span>

        <div className="flex w-full text-center ">
          <div className="flex content-center w-full h-auto p-1 text-center">
            <button
              onClick={() =>
                openInNewTab(
                  "https://apps.apple.com/app/beder-l-explore-travel/id1609301338"
                )
              }
            >
              <Image
                src={AppStore}
                width={300}
                height={200}
                alt="App Store logo"
              />
            </button>
          </div>
          <div className="flex w-full p-1 text-center">
            <button
              onClick={() =>
                openInNewTab(
                  "https://play.google.com/store/apps/details?id=com.bederapp"
                )
              }
            >
              <Image
                src={PlayStore}
                width={300}
                height={200}
                alt="Play Store logo"
              />
            </button>
          </div>
        </div>
      </div>
      {/* BOTTOM PART */}
      <div className="mt-20 mb-32 text-center md:mb-5">
        © 2024 <span className="text-teal-300">beder </span>
        All Rights Reserved
      </div>
    </div>
  );
};
