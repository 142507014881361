import { useRouter } from "next/router";
import languageDetector from "@lib/languageDetector";
import { DropdownListSelect } from "./fullScreenVideo/musement-activity/DropDownListSelect";
import { DropDownListItem } from "./fullScreenVideo/musement-activity/DropDownListItem";
import Cookies from "js-cookie";
import CheckIcon from "../../public/check.png";
import Image from "next/image";
import { useState } from "react";
import { i18n, useTranslation } from "next-i18next";



export default function CurrencySwitcher( ) {
  const router = useRouter();
  const { t } = useTranslation();
 


  var currencyDefault = t("Currency");
  
  if(Cookies.get('currency') !== undefined)
      currencyDefault =  Cookies.get('currency')!.toString();


  const [showCurrencyList, setShowCurrencyList] = useState<boolean>(false);
  const [showCurrencyValue, setShowCurrencyValue] = useState<string>(currencyDefault);

  const changeCurreny = (currency: string) => {
    setShowCurrencyValue(currency);
    //cache currency
    Cookies.set("currency",currency, {
      expires: 365,
    });

    setCurrencyVisible();
    const { pathname, asPath, query } = router;

    //soft refresh
    router.replace({ pathname, query }, asPath, { locale: i18n?.language });
  };

  const setCurrencyVisible =() => {

    setShowCurrencyList(!showCurrencyList);
  };

  const langCombo = ['USD','GBP','EUR'];


  return (
    <>
    <div className="items-center justify-center absolute top-4 ">
      <ul className=" items-center justify-center absolute hidden md:visible md:flex">
        <DropdownListSelect
          leftIcon={<Image  src={CheckIcon} width={0} alt={""} className=""/>}
          rightIcon={<Image  src={CheckIcon} width={0} alt={""} className="w-0"/>}
          isShown={showCurrencyList}
          setIsShown={()=>setCurrencyVisible()}
          selectedState={showCurrencyValue}
          additionalStyles={'!bg-transparent'}
          
          isFilled={true}
        >
          {langCombo.map((cur, idx) => (
            <DropDownListItem
              key={idx}
              isSelected={cur === Cookies.get('currency')}
              stateTitle={cur}
              onClick={() => changeCurreny(cur)}
              additionalStyles="border-b-2 border-slate-200"
            />
          ))}
       </DropdownListSelect>
      </ul>
    </div>
    <div>
    <ul className="flex items-center justify-center md:hidden">
      
            <li key={'EUR'} className="flex">
              <button
                className={`uppercase ${
                  'EUR' === Cookies.get('currency') ? "text-primary-color" : "text-black"
                }`}
                onClick={() => changeCurreny('EUR')}
              >
                {'EUR'}
              </button>
                <span className="mx-0.5">|</span>
                <button
                className={`uppercase ${
                  'GBP' === Cookies.get('currency') ? "text-primary-color" : "text-black"
                }`}
                onClick={() => changeCurreny('GBP')}
              >
                {'GBP'}
              </button>
              <span className="mx-0.5">|</span>
              <button
                className={`uppercase ${
                  'USD' === Cookies.get('currency') ? "text-primary-color" : "text-black"
                }`}
                onClick={() => changeCurreny('USD')}
              >
                {'USD'}
              </button>
            </li>
      </ul>

    </div>
    </>
  );
}
