import { IoChevronDownOutline } from "react-icons/io5";

export const DropdownListSelect = ({
  children,
  leftIcon,
  rightIcon,
  isShown,
  setIsShown,
  selectedState,
  isFilled,
  additionalStyles,
}: {
  children?: React.ReactNode;
  leftIcon: JSX.Element;
  rightIcon: JSX.Element;
  isShown: boolean;
  setIsShown: React.Dispatch<React.SetStateAction<boolean>>;
  selectedState: string | Date | null;
  isFilled: boolean;
  additionalStyles: string | null;
}) => {
  return (
    <div className="flex flex-col mb-2">
      <div
        className={`flex items-center w-full h-10 text-center cursor-pointer bg-white ${
          isFilled ? "border-2 border-primary-color rounded-md" : "border-2 border-slate-300"
        }  ${additionalStyles}`}
        onClick={() => setIsShown(isShown)}
      >
        <div className={"flex items-center w-full  px-2 "}>
          <div className="flex flex-1">
            {/* {children} */}
            <div className="justify-left align-left	my-auto">{leftIcon}</div>
            <div className="ml-1 text-sm font-semibold">
              {selectedState?.toString()}
            </div>
          </div>
          <div className="mr-1">{isFilled && rightIcon}</div>
          <IoChevronDownOutline size={18} />
        </div>
      </div>
      {isShown && (
        <div className="bg-white broder-[4px] mt-0 border-2 border-slate-300 rounded">
          {children}
        </div>
      )}
    </div>
  );
};
