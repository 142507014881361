import { useState } from "react";
import Image from "next/image";
import Link from "next/link";
import { useTranslation } from "next-i18next";
import { SpecialText } from "./shared";
import { useMobileDetect } from "@hooks/useMobileDetect";

import handCool from "../assets/hand_cool.png";
import LogoImg from "../../public/images/logo-beder-opt-1024x422.png";
import { useRouter } from "next/router";
import languageDetector from "@lib/languageDetector";

export const Navbar = () => {
  const baseUrlBederApp = "https://theapp.beder.app";

  const { t: translate, i18n } = useTranslation();

  const router = useRouter();
  const [navbar, setNavbar] = useState(false);

  const openInNewTab = (url: string | URL | undefined) => {
    window.open(url, "_blank", "noreferrer");
  };

  const { isAndroid } = useMobileDetect();
  const downloadApp = () => {
    const playStoreUrl =
      "https://play.google.com/store/apps/details?id=com.bederapp";
    const appStoreUrl =
      "https://apps.apple.com/app/beder-l-explore-travel/id1609301338";

    const appUrl = isAndroid() ? playStoreUrl : appStoreUrl;

    openInNewTab(appUrl);
  };

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
    languageDetector.cache?.(language);
    router.replace(router.pathname, router.asPath, {
      locale: language,
    });
  };

  return (
    <nav className="fixed top-0 w-full shadow backdrop-blur-3xl z-[999]">
      <div className="justify-between px-0 mx-auto lg:max-w-7xl md:items-center md:flex">
        <div className="flex items-center justify-between px-4 md:block">
          <div className="md:hidden">
            <button
              className="p-2 text-gray-700 w-7"
              onClick={() => setNavbar(!navbar)}
            >
              {navbar ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="text-black w-7 h-7"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4 12h16M4"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="text-black w-7 h-7"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              )}
            </button>
          </div>
          <Link href="/home">
            <Image src={LogoImg} width={110} alt={"Beder logo"} />
          </Link>
          <div className="md:hidden w-7" />
        </div>
        <div
          className={`md:bg-transparent absolute md:static cursor-pointer md:cursor-auto flex-1 justify-self-center md:block md:pb-0 md:mt-0 border-t-8 rounded-br-[32px] border-black md:border-0 w-full pr-[10%] md:pr-0 h-screen md:h-auto ${
            navbar ? "block" : "hidden"
          }`}
          onClick={() => setNavbar(false)}
        >
          <ul className="bg-white md:bg-transparent flex flex-col md:flex-row justify-end md:space-x-6 md:space-y-0 py-4 px-8 md:px-4 gap-4 whitespace-nowrap uppercase font-light md:font-normal">
            <li className="border-b md:border-0 border-primary-color py-2">
              <Link href="/home">
                {translate("HOME_HEADER_MAIN_WEB_PART1")}{" "}
                <span className="text-primary-color">
                  {translate("HOME_HEADER_MAIN_WEB_PART2")}
                </span>
              </Link>
            </li>
            <li className="border-b md:border-0 border-primary-color py-2">
              <Link href="/theapp">
                {translate("HOME_HEADER_THE_APP_PART1")}{" "}
                <span className="text-primary-color">
                  {translate("HOME_HEADER_THE_APP_PART2")}
                </span>
              </Link>
            </li>
            <li className="border-b md:border-0 border-primary-color py-2">
            <Link href="/aboutus">
            {translate("ABOUT").toUpperCase()}{" "}
                <span className="text-primary-color">
                  {translate("US").toUpperCase()}
                </span>
              </Link>
            </li>
            <li className="border-b md:border-0 border-primary-color py-2">
            <Link href="/bepartof">
            {i18n.language === "en" ? (
                  <>
                    {" "}
                    {translate("SPECIAL_BE_PART_OF1").toUpperCase()}{" "}
                    <span className="text-primary-color">
                      {translate("SPECIAL_BE_PART_OF2").toUpperCase()}
                    </span>{" "}
                    {translate("SPECIAL_BE_PART_OF3").toUpperCase()}
                  </>
                ) : (
                  <SpecialText
                    text={translate("HOME_HEADER_THE_BE_PART_OF").toUpperCase()}
                  />
                )}
              </Link>
            
            </li>
            <li className="md:hidden border-b md:border-0 border-primary-color py-2">
              <button onClick={downloadApp}>
                {translate("DOWNLOAD").toUpperCase()}{" "}
                <span className="text-primary-color">
                  {translate("THE_APP").toUpperCase()}
                </span>
              </button>
            </li>
            <li className="flex justify-between py-2">
              <p className="md:hidden">{translate("LANGUAGE")}</p>
              <div>
                <button
                  className={
                    i18n.language === "en"
                      ? "text-primary-color border-b md:border-0 border-primary-color pb-1"
                      : ""
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    changeLanguage("en");
                  }}
                >
                  {translate("EN")}
                </button>
                <span> | </span>
                <button
                  className={
                    i18n.language === "es"
                      ? "text-primary-color border-b md:border-0 border-primary-color pb-1"
                      : ""
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    changeLanguage("es");
                  }}
                >
                  {translate("ES")}
                </button>
                <span> | </span>
                <button
                  className={
                    i18n.language === "fr"
                      ? "text-primary-color border-b md:border-0 border-primary-color pb-1"
                      : ""
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    changeLanguage("fr");
                  }}
                >
                  {translate("FR")}
                </button>
              </div>
            </li>
          </ul>
          <div className="md:hidden flex flex-col justify-between items-center gap-y-2 rounded-br-[32px] pt-6 pb-2 bg-[#9BFFEF]">
            <div className="flex flex-col justify-center items-center p-4">
              <Image src={handCool} alt="hand" className="" width={50} />
              <div className="uppercase text-lg py-2">
                <h4>life is short,</h4>
                <h4>travel hard</h4>
              </div>
            </div>
            <p className="text-xs font-light text-[#5A5959]">
              © 2024 beder app | All Rights Reserved
            </p>
          </div>
        </div>
      </div>
    </nav>
  );
};
