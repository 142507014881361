import { useRouter } from "next/router";
import languageDetector from "@lib/languageDetector";

export default function LocaleSwitcher() {
  const router = useRouter();

  const changeLocale = (language: string) => {
    languageDetector.cache?.(language);
    const { pathname, asPath, query } = router;
    router.replace({ pathname, query }, asPath, { locale: language });
  };

  
  return (
    <div>
      <ul className="flex items-center justify-center">
        {router.locales!.map((locale, idx) => {
          return (
            <li key={locale} className="flex">
              <button
                className={`uppercase ${
                  router.locale === locale ? "text-primary-color" : "text-black"
                }`}
                onClick={() => changeLocale(locale)}
              >
                {locale}
              </button>
              {idx < router.locales!.length - 1 && (
                <span className="mx-0.5">|</span>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
}
