import React from "react";
import AppStore from "../../public/images/app-store.png";
import Aff1 from "../../public/images/affiliate-companies/aff-1.svg"
import Image from "next/image";

export const AppModal = () => {
  return (
    <div className="absolute flex flex-col items-center justify-center border-slate-300 border-2 space-y-10 bg-white rounded shadow-2xl top-16 right-[39vw] h-96 w-96">
      <div className="flex items-center justify-center w-1/2 h-40 border-2 rounded-lg">
        APP
      </div>
      <div className="justify-center w-1/2 h-10 text-center bg-black">
        <Image src={Aff1} alt={"logo"} className={"object-cover max-h-full max-w-full"} />
      </div>
      <div className="justify-center w-1/2 h-10 text-center bg-red-500">
        <Image src={AppStore} alt={""} className={"object-cover max-h-full max-w-full"} />
      </div>
    </div>
  );
};
